<template>
  <table class="table">
    <caption v-if="caption">
      <span>{{ caption }}</span>
    </caption>
    <tr>
      <th v-for="(header, index) in headers" :key="index" class="table__header-cell">
        <div class="flex--align-center">
          <span class="header-label">{{ getLabel(header) }}</span>
          <div
            class="arrow-container"
            :class="getArrowClassesObject(index)"
            @click="onSortingArrowClick(index)"
          ></div>
        </div>
      </th>
    </tr>
    <template v-for="(row, rowIndex) in sortedList">
      <tr
        :key="rowIndex"
        class="item-row"
        :class="{ 'item-row--opened': hasRowOpenedDetails(rowIndex) }"
      >
        <td v-for="(header, cellIndex) in headers" :key="cellIndex" class="item-cell">
          <div class="item-cell__header">{{ getLabel(header) }}</div>
          <slot :name="getItemSlotName(header.value)" :item="row" :rowIndex="rowIndex">
            <div class="flex--align-center">
              {{ row[header.value] }}
            </div>
          </slot>
        </td>

        <div class="item-row-toggle">
          <slot name="toggleRowHeader"></slot>
          <span
            class="fas fa-chevron-down item-row-toggle__arrow-icon pointer"
            :class="{ 'item-row-toggle__arrow-icon--opened': hasRowOpenedDetails(rowIndex) }"
            @click="toggleRowDetails(rowIndex, row)"
          ></span>
        </div>
      </tr>

      <tr
        v-if="hasRowOpenedDetails(rowIndex)"
        :key="rowIndex + '-expanded'"
        class="item-row__details"
      >
        <slot name="rowDetails" :rowIndex="rowIndex"></slot>
      </tr>
    </template>
  </table>
</template>

<script>
import { SORTING_ORDER } from '@/modules/dashboard/api/constants';

export default {
  name: 'Table',
  props: {
    caption: {
      type: String,
      default: ''
    },
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    defaultSortIndex: {
      type: [Number, null],
      default: null
    },
    defaultSortOrder: {
      type: [String],
      default: SORTING_ORDER.DESC
    }
  },
  data() {
    return {
      sortDirection: this.defaultSortOrder,
      sortIndex: this.defaultSortIndex,
      openedRows: []
    };
  },
  computed: {
    sortedList() {
      const itemsCopy = [...this.items];
      if (this.sortIndex === null) {
        return itemsCopy;
      }

      const { value: fieldToSort } = this.headers[this.sortIndex];
      const modifier = this.sortDirection === SORTING_ORDER.ASC ? 1 : -1;

      return itemsCopy.sort((a, b) => {
        const comparison = a[fieldToSort] >= b[fieldToSort];

        return comparison ? modifier : -modifier;
      });
    }
  },
  methods: {
    getLabel({ label, isLabelKey }) {
      return isLabelKey ? this.$t(label) : label;
    },
    getItemSlotName(columnName) {
      return `item.${columnName}`;
    },
    getArrowClassesObject(index) {
      return {
        'sort-none': index !== this.sortIndex,
        'sort-asc': index === this.sortIndex && this.sortDirection === SORTING_ORDER.ASC
      };
    },
    onSortingArrowClick(index) {
      if (this.sortIndex === null || this.sortIndex !== index) {
        this.sortIndex = index;
        this.sortDirection = SORTING_ORDER.DESC;

        return;
      }

      if (this.sortDirection === SORTING_ORDER.DESC) {
        this.sortDirection = SORTING_ORDER.ASC;

        return;
      }

      this.sortIndex = null;
    },
    hasRowOpenedDetails(rowIndex) {
      return this.openedRows.includes(rowIndex);
    },
    toggleRowDetails(rowIndex, data) {
      if (this.hasRowOpenedDetails(rowIndex)) {
        const foundItemIndex = this.openedRows.findIndex(item => item === rowIndex);

        this.openedRows.splice(foundItemIndex, 1);

        return;
      }

      this.openedRows.push(rowIndex);
      this.$emit('open-details', { ...data, rowIndex });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/css/common/icons';

.header {
  width: 100%;
}

.arrow-container {
  width: 8px;
  height: 8px;
  margin-left: 8px;
  cursor: pointer;
  background: url('~@/assets/images/dashboard/icons/arrow-down-icon.svg') no-repeat center center;
  background-size: 100%;
}

.sort-none {
  background-image: none;
}

.sort-none:hover,
.sort-asc {
  transform: rotate(180deg);
}

.table {
  width: 100%;
  max-width: 100%;
  border-spacing: 0;
  table-layout: fixed;
  margin-bottom: 20px;
  border-collapse: collapse;
  background-color: transparent;

  &__header-cell {
    flex-basis: 0;
    flex-grow: 1;
    padding: 0 0 12px;
    margin-bottom: 8px;
    color: $text-color;
    border-bottom: 1px solid $dim-white;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;

    &:first-child {
      padding-left: 20px;
    }
  }
}

.item-row {
  height: 80px;
  margin: 20px 0;
  padding: 0 20px;

  &--opened {
    border-top: 1px solid $divider-grey-color;
    background-color: $white;
  }

  &--toggle {
    display: flex;

    &__header {
      display: none;
    }

    &__arrow-icon {
      margin-left: 20px;
      font-size: 12px;
      line-height: 80px;
      color: $dim-grey-color;

      &--opened {
        transform: rotate(180deg);
      }
    }
  }
}

.item-cell {
  padding: 0;

  &:first-child {
    padding-left: 20px;
  }

  &__header {
    display: none;
  }
}

.item-row-toggle {
  display: flex;

  &__header {
    display: none;
  }

  &__arrow-icon {
    margin-left: 20px;
    font-size: 12px;
    line-height: 80px;
    color: $dim-grey-color;

    &--opened {
      transform: rotate(180deg);
    }
  }
}

.details-conatiner {
  background-color: $white;
  width: 980px;
}

@media (max-width: 767px) {
  .table {
    &__header-cell {
      display: none;
    }
  }

  .item-row {
    display: block;
    height: auto;
    margin: 0;
    padding: 20px 20px 20px;
    border-bottom: 2px solid $dim-white;

    &--opened {
      margin: 0;
      border: none;
    }
  }

  .item-cell {
    display: grid;
    grid-template-columns: 1fr 1fr;

    &__header {
      display: block;
      padding: 0 20px 10px 0;

      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 15px;
    }

    &__arrow-icon {
      margin-left: 20px;
      line-height: 16px;
    }

    &:first-child {
      padding-left: 0;
    }
  }

  .item-row-toggle {
    display: flex;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;

    &__arrow-icon {
      margin-left: 20px;
      line-height: 15px;
    }
  }

  .item-row__details {
    border-bottom: 2px solid $dim-white;
  }
}
</style>
