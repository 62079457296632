<template>
  <div class="influencers-container">
    <div v-if="isLoading"></div>

    <div v-else>
      <div class="influencers-container__title">
        {{ $t('dashboard.influencerStatistic.influencerActivity') }}
      </div>

      <div v-if="!influencersData.length" class="influencers-container__default-content">
        <i18n path="dashboard.influencerStatistic.defaultInfluencersActivityMessage" tag="span">
          <a class="link open-chat-link mr5" @click="openLiveChat">
            {{ $t('dashboard.influencerStatistic.openChat') }}
          </a>
        </i18n>
      </div>

      <div v-else>
        <table class="influencers-table">
          <tr class="influencers-table__header">
            <th
              v-for="(header, index) in $options.tableHeaders"
              :key="index"
              class="influencers-table__header-cell"
            >
              {{ $t(header.label) }}
            </th>
          </tr>

          <tbody class="influencers-table__body">
            <tr
              v-for="(row, rowIndex) in influencersData"
              :key="rowIndex"
              class="influencers-table__row"
            >
              <td
                v-for="(header, cellIndex) in $options.tableHeaders"
                :key="cellIndex"
                class="influencers-table__cell cell"
              >
                <div class="cell__header">{{ $t(header.label) }}</div>
                <div
                  class="cell__value"
                  :class="{ 'cell__value--bold': header.value === 'influencerName' }"
                >
                  {{ row[header.value] }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import { isMobileDevice } from '@/modules/dashboard/utils';

import { types } from '@/store/types';

const TABLE_HEADERS_CONFIG = [
  { label: 'dashboard.influencerStatistic.influencer', value: 'influencerName' },
  { label: 'dashboard.influencerStatistic.questionnaires', value: 'questionnairesCompleted' },
  { label: 'dashboard.influencerStatistic.orders', value: 'ordersDelivered' }
];

export default {
  name: 'InfluencerStatistic',
  tableHeaders: TABLE_HEADERS_CONFIG,
  props: {
    influencersData: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state[types.state.STATUS].loading
    })
  },
  methods: {
    openLiveChat() {
      if (isMobileDevice()) {
        window.frames.LC_API.open_mobile_window();

        return;
      }

      window.frames.LC_API.open_chat_window();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';

.influencers-container {
  width: 980px;
  padding: 0 20px 20px 40px;
  font-size: 13px;
  background-color: $white;

  &__title {
    width: 200px;
    padding-bottom: 12px;
    color: $text-color;
    font-weight: 600;
  }
}

.influencers-table {
  width: 90%;
  border-spacing: 0;
  border-collapse: collapse;

  &__header {
    display: grid;
    grid-template-columns: 1.1fr 1.3fr 2fr;
    height: 30px;

    &-cell {
      font-weight: normal;
      text-align: left;
    }
  }

  &__body {
    margin-top: 16px;
    border-top: 1px solid $divider-grey-color;
  }

  &__row {
    display: grid;
    grid-template-columns: 1.1fr 1.3fr 2fr;
    padding-top: 15px;
  }
}

.cell {
  display: grid;
  padding: 0;

  &__header {
    display: none;
  }

  &__value {
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;

    &--bold {
      font-weight: 600;
    }
  }
}

.open-chat-link {
  color: #526d9f;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-decoration-line: underline;
}

@media (max-width: 767px) {
  .influencers-container {
    width: auto;
    padding: 0 20px 20px 20px;

    &__title {
      display: none;
    }

    &__default-content {
      width: 80%;
    }
  }

  .influencers-table {
    width: 100%;

    &__header {
      display: none;
    }

    &__body {
      margin-top: 16px;
      border-top: none;
    }

    &__row {
      display: block;
      height: auto;
      padding: 0;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  .cell {
    display: grid;
    grid-template-columns: 1fr 1fr;

    &__header {
      display: block;
      padding: 0 20px 10px 0;
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 16px;
    }
  }
}
</style>
