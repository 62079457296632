<template>
  <modal
    :name="$options.modalName"
    :classes="['modal-window']"
    transition="scale"
    :adaptive="true"
    :width="418"
    height="auto"
    @before-open="initData"
  >
    <div class="modal-container send-consultation-fee-invoice-modal">
      <i18n
        class="main-text send-consultation-fee-invoice-modal__message"
        tag="p"
        path="dashboard.consultationFee.popUpSendInvoice"
      >
        <span>{{ reportingDate }}</span>
        <span>{{ feePerMonth }}</span>
        <span>{{ consultationTimeFormatted }}</span>
        <span>{{ doctorHourlyRate }}</span>
        <a class="send-consultation-fee-invoice-modal__link" href="mailto://info@universkin.com">
          info@universkin.com
        </a>
      </i18n>
      <p class="secondary-text send-consultation-fee-invoice-modal__secondary-text">
        {{ $t('dashboard.consultationFee.distributorContactDetails') }}
      </p>
      <div
        class="actions send-consultation-fee-invoice-modal__actions flex justify-content--center"
      >
        <button class="rounded-button-gray actions__button" @click="onClose">
          {{ $t('dashboard.action.cancel') }}
        </button>
        <button class="rounded-button-white actions__button" @click="onSendInvoice">
          {{ $t('dashboard.consultationFee.sendInvoice') }}
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import rootTypes from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';

import { formatPrice, checkIsPdfFile } from '@/modules/dashboard/utils';
import { formatDate } from '@/modules/dashboard/utils/date-utils';

import { uploadConsultationFeeInvoice } from '@/modules/dashboard/api';

import { FULL_MONTH_NAME_AND_FULL_YEAR_PATTERN } from '@/constants';

export default {
  name: 'SendConsultationFeeInvoiceModal',
  modalName: 'send-consultation-fee-invoice-modal',
  data() {
    return {
      invoiceFileInput: null,
      year: null,
      month: null,
      fee: null,
      consultationTime: null
    };
  },
  computed: {
    ...mapGetters({
      currency: dashTypes.getters.GET_CURRENCY,
      doctorConsultationCost: rootTypes.getters.DOCTOR_CONSULTATION_COST,
      doctorId: rootTypes.getters.GET_USER_ID,
      doctorCountry: rootTypes.getters.GET_USER_COUNTRY
    }),
    reportingDate() {
      return formatDate(new Date(this.year, this.month), FULL_MONTH_NAME_AND_FULL_YEAR_PATTERN);
    },
    feePerMonth() {
      return formatPrice({ currency: this.currency, price: this.fee, useCurrencySymbol: true });
    },
    doctorHourlyRate() {
      return formatPrice({
        currency: this.currency,
        price: this.doctorConsultationCost,
        useCurrencySymbol: true
      });
    },
    consultationTimeFormatted() {
      return `${this.consultationTime} ${this.$t('dashboard.consultationFee.min')}`;
    }
  },
  mounted() {
    this.initFileInput();
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING
    }),
    initData({ params }) {
      const { year, month, fee, consultationTime } = params;

      this.year = year;
      this.month = month;
      this.fee = fee;
      this.consultationTime = consultationTime;
    },
    initFileInput() {
      const fileInput = document.createElement('input');

      fileInput.type = 'file';
      fileInput.accept = '.pdf';
      fileInput.addEventListener('change', e => {
        this.uploadFiles(e.target.files);
      });
      this.invoiceFileInput = fileInput;
    },
    onClose() {
      this.$emit('close');
      this.$modal.hide(this.$options.modalName);
    },
    onSendInvoice() {
      this.invoiceFileInput.click();
    },
    async uploadFiles(files) {
      if (!files || files.length === 0) {
        return;
      }

      const [file] = files;

      if (!checkIsPdfFile(file)) {
        throw Error('The file is not pdf');
      }

      this.setLoading(true);

      await uploadConsultationFeeInvoice({
        doctorId: this.doctorId,
        month: this.month + 1,
        year: this.year,
        file
      });

      this.setLoading(false);
      this.onClose();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/helpers/colors';
@import '../../assets/scss/common/buttons';
@import '../../assets/css/common/text';

.send-consultation-fee-invoice-modal {
  padding: 30px;

  &__message {
    padding: 30px 18px;
    margin: 0 0 20px 0;
    background-color: $beige;
    border-radius: 8px;
    text-align: left;
  }

  &__secondary-text {
    text-align: center;
  }

  &__link,
  &__link:link,
  &__link:hover {
    color: $blue-steel-color;
    font-weight: 500;
    text-decoration-line: none;
  }
}

.actions {
  margin-top: 30px;

  &__button:first-child {
    margin-right: 20px;
  }
}
</style>
