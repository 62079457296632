var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('template-with-header',[_c('div',{staticClass:"fee__container"},[_c('div',{staticClass:"fee__breadcrumbs"},[_c('span',{staticClass:"breadcrumbs__link"},[_c('router-link',{attrs:{"to":{ name: 'Dashboard' },"title":_vm.$t('dashboard.action.home')}},[_c('span',[_vm._v(_vm._s(_vm._f("upperCase")(_vm.$t('dashboard.action.home'))))])])],1),_vm._v(" / "),_c('span',{staticClass:"breadcrumbs__current no-search-field"},[_vm._v(" "+_vm._s(_vm._f("upperCase")(_vm.$t('dashboard.label.onlineConsultationFee')))+" ")])]),_c('div',{staticClass:"flex--align-center description"},[_c('img',{staticClass:"mx-40",attrs:{"src":require("@/assets/images/dashboard/icons/web-developer.svg"),"alt":"Doctor picture"}}),_c('div',{staticClass:"mx-40 content"},[_c('p',{staticClass:"content__text"},[_vm._v(_vm._s(_vm.$t('dashboard.consultationFee.introduction')))])])])]),_c('div',{staticClass:"table-wrapper"},[_c('app-table',{attrs:{"headers":_vm.$options.tableHeaders,"items":_vm.payments,"influencers-data":_vm.influencersData},on:{"open-details":_vm.loadInfluencerData},scopedSlots:_vm._u([{key:"item.month",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex-column cell-wrapper cell-font month-cell-wrapper"},[_c('div',{staticClass:"font-bold mr5 mb5"},[_vm._v(_vm._s(_vm.getFullMonthName(item)))]),_c('div',[_vm._v(_vm._s(item.year))])])]}},{key:"item.offersSent",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex-column cell-wrapper cell-font font-bold"},[_c('div',{staticClass:"mr10 mb5"},[_vm._v(" "+_vm._s(item.offersSent)+" ")])])]}},{key:"item.offersPaid",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex-column cell-wrapper cell-font font-bold"},[_c('div',{staticClass:"mr10 mb5"},[_vm._v(" "+_vm._s(item.offersPaid)+" ")])])]}},{key:"item.renewalsPaid",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex-column cell-wrapper cell-font font-bold"},[_c('div',{staticClass:"mr10 mb5"},[_vm._v(" "+_vm._s(item.renewalsPaid)+" ")])])]}},{key:"item.consultationTime",fn:function(ref){
var item = ref.item;
var rowIndex = ref.rowIndex;
return [_c('div',{staticClass:"flex-column cell-wrapper cell-font"},[_c('div',{staticClass:"font-bold mr10 mb5 text-nowrap"},[_vm._v(" "+_vm._s(item.consultationTime)+" "+_vm._s(_vm.$t('dashboard.consultationFee.min'))+" ")]),(rowIndex === 0)?_c('div',{staticClass:"font-smaller"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.consultationFee.nextUpdate'))+" "+_vm._s(_vm.getFormattedDate(_vm.nextStatisticUpdate))+" ")]):_vm._e()])]}},{key:"item.consultationAmount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex-column cell-wrapper cell-font font-bold"},[_c('div',{staticClass:"mr10 mb5"},[_vm._v(" "+_vm._s(((item.doctorNationalCurrency) + " " + (item.consultationAmountInNationalCurrency)))+" ")])])]}},{key:"item.paymentDate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex-column cell-wrapper payment-cell-wrapper"},[(_vm.getShouldDisplaySendInvoiceButton(item))?[_c('div',{staticClass:"cell-font"},[_c('div',{staticClass:"mb5"},[_vm._v(_vm._s(_vm.$t('dashboard.consultationFee.waitingForInvoice')))])]),_c('a',{staticClass:"invoice-action-link",on:{"click":function($event){$event.preventDefault();return _vm.showModal(item)}}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.consultationFee.sendInvoice'))+" ")])]:_vm._e(),(!item.paid && item.hasInvoice)?[_c('div',{staticClass:"cell-font"},[_c('div',{staticClass:"mb5"},[_vm._v(_vm._s(_vm.$t('dashboard.consultationFee.invoiceSent')))])]),_c('a',{staticClass:"invoice-action-link",attrs:{"target":"_blank"},on:{"click":function($event){$event.preventDefault();return _vm.downloadInvoice(item)}}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.consultationFee.downloadInvoice'))+" ")])]:_vm._e(),(item.paid)?[_c('div',{staticClass:"cell-font font-bold"},[_c('div',{staticClass:"font-bold mb5"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.consultationFee.paid'))+" "+_vm._s(_vm.getFormattedDate(item.paymentDate))+" ")]),_c('a',{staticClass:"invoice-action-link",attrs:{"target":"_blank"},on:{"click":function($event){$event.preventDefault();return _vm.downloadInvoice(item)}}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.consultationFee.downloadInvoice'))+" ")])])]:_vm._e()],2)]}},{key:"rowDetails",fn:function(ref){
var rowIndex = ref.rowIndex;
return [_c('influencer-statistic',{attrs:{"influencers-data":_vm.influencersData[rowIndex]}})]}},{key:"toggleRowHeader",fn:function(){return [_c('span',{staticClass:"toggle-row-header"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.influencerStatistic.influencerActivity'))+" ")])]},proxy:true}])})],1),_c('send-consultation-fee-invoice-modal',{on:{"close":_vm.loadFeeStatisticsList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }