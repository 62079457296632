<template>
  <template-with-header>
    <div class="fee__container">
      <div class="fee__breadcrumbs">
        <span class="breadcrumbs__link">
          <router-link :to="{ name: 'Dashboard' }" :title="$t('dashboard.action.home')">
            <span>{{ $t('dashboard.action.home') | upperCase }}</span>
          </router-link>
        </span>
        /
        <span class="breadcrumbs__current no-search-field">
          {{ $t('dashboard.label.onlineConsultationFee') | upperCase }}
        </span>
      </div>

      <div class="flex--align-center description">
        <img
          class="mx-40"
          src="@/assets/images/dashboard/icons/web-developer.svg"
          alt="Doctor picture"
        />
        <div class="mx-40 content">
          <p class="content__text">{{ $t('dashboard.consultationFee.introduction') }}</p>
        </div>
      </div>
    </div>

    <div class="table-wrapper">
      <app-table
        :headers="$options.tableHeaders"
        :items="payments"
        :influencers-data="influencersData"
        @open-details="loadInfluencerData"
      >
        <template #item.month="{ item }">
          <div class="flex-column cell-wrapper cell-font month-cell-wrapper">
            <div class="font-bold mr5 mb5">{{ getFullMonthName(item) }}</div>
            <div>{{ item.year }}</div>
          </div>
        </template>

        <template #item.offersSent="{ item }">
          <div class="flex-column cell-wrapper cell-font font-bold">
            <div class="mr10 mb5">
              {{ item.offersSent }}
            </div>
          </div>
        </template>

        <template #item.offersPaid="{ item }">
          <div class="flex-column cell-wrapper cell-font font-bold">
            <div class="mr10 mb5">
              {{ item.offersPaid }}
            </div>
          </div>
        </template>

        <template #item.renewalsPaid="{ item }">
          <div class="flex-column cell-wrapper cell-font font-bold">
            <div class="mr10 mb5">
              {{ item.renewalsPaid }}
            </div>
          </div>
        </template>

        <template #item.consultationTime="{ item, rowIndex }">
          <div class="flex-column cell-wrapper cell-font">
            <div class="font-bold mr10 mb5 text-nowrap">
              {{ item.consultationTime }} {{ $t('dashboard.consultationFee.min') }}
            </div>
            <div v-if="rowIndex === 0" class="font-smaller">
              {{ $t('dashboard.consultationFee.nextUpdate') }}
              {{ getFormattedDate(nextStatisticUpdate) }}
            </div>
          </div>
        </template>

        <template #item.consultationAmount="{ item }">
          <div class="flex-column cell-wrapper cell-font font-bold">
            <div class="mr10 mb5">
              {{ `${item.doctorNationalCurrency} ${item.consultationAmountInNationalCurrency}` }}
            </div>
          </div>
        </template>

        <template #item.paymentDate="{ item }">
          <div class="flex-column cell-wrapper payment-cell-wrapper">
            <template v-if="getShouldDisplaySendInvoiceButton(item)">
              <div class="cell-font">
                <div class="mb5">{{ $t('dashboard.consultationFee.waitingForInvoice') }}</div>
              </div>
              <a class="invoice-action-link" @click.prevent="showModal(item)">
                {{ $t('dashboard.consultationFee.sendInvoice') }}
              </a>
            </template>
            <template v-if="!item.paid && item.hasInvoice">
              <div class="cell-font">
                <div class="mb5">{{ $t('dashboard.consultationFee.invoiceSent') }}</div>
              </div>
              <a class="invoice-action-link" target="_blank" @click.prevent="downloadInvoice(item)">
                {{ $t('dashboard.consultationFee.downloadInvoice') }}
              </a>
            </template>
            <template v-if="item.paid">
              <div class="cell-font font-bold">
                <div class="font-bold mb5">
                  {{ $t('dashboard.consultationFee.paid') }}
                  {{ getFormattedDate(item.paymentDate) }}
                </div>
                <a
                  class="invoice-action-link"
                  target="_blank"
                  @click.prevent="downloadInvoice(item)"
                >
                  {{ $t('dashboard.consultationFee.downloadInvoice') }}
                </a>
              </div>
            </template>
          </div>
        </template>

        <template #rowDetails="{ rowIndex }">
          <influencer-statistic :influencers-data="influencersData[rowIndex]" />
        </template>

        <template #toggleRowHeader>
          <span class="toggle-row-header">
            {{ $t('dashboard.influencerStatistic.influencerActivity') }}
          </span>
        </template>
      </app-table>
    </div>
    <send-consultation-fee-invoice-modal @close="loadFeeStatisticsList" />
  </template-with-header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { upperFirst, range } from 'lodash';

import TemplateWithHeader from '@/modules/dashboard/components/templates/TemplateWithHeader';
import AppTable from '@/modules/dashboard/components/common/Table';
import SendConsultationFeeInvoiceModal from '@/views/Modals/SendConsultationFeeInvoiceModal';
import InfluencerStatistic from '@/modules/dashboard/components/consultation-fee/InfluencerStatistic.vue';

import { formatDate } from '@/modules/dashboard/utils/date-utils';
import { downloadFile } from '@/modules/dashboard/utils';

import { PDF_APPLICATION_TYPE } from '@/modules/dashboard/api/constants';
import {
  FULL_MONTH_NAME_PATTERN,
  SMALL_MONTH_NAME_PATTERN,
  FULL_MONTH_NAME_AND_FULL_YEAR_PATTERN
} from '@/constants';

import {
  getConsultationFeeStatistics,
  downloadConsultationFeeInvoice,
  getInfluencerStatistic
} from '@/modules/dashboard/api';

import rootTypes from '@/store/types';

const TABLE_HEADERS_CONFIG = [
  { label: 'dashboard.consultationFee.month', value: 'month', isLabelKey: true },
  { label: 'dashboard.consultationFee.offersSent', value: 'offersSent', isLabelKey: true },
  { label: 'dashboard.consultationFee.offersPaid', value: 'offersPaid', isLabelKey: true },
  { label: 'dashboard.consultationFee.renewalsPaid', value: 'renewalsPaid', isLabelKey: true },
  {
    label: 'dashboard.consultationFee.consultationTime',
    value: 'consultationTime',
    isLabelKey: true
  },
  { label: 'dashboard.consultationFee.fee', value: 'consultationAmount', isLabelKey: true },
  { label: 'dashboard.consultationFee.paymentStatus', value: 'paymentDate', isLabelKey: true }
];

const CONSULTATION_CALCULATION_START_YEAR = 2020;

export default {
  name: 'OnlineConsultationFee',
  components: {
    TemplateWithHeader,
    AppTable,
    SendConsultationFeeInvoiceModal,
    InfluencerStatistic
  },
  tableHeaders: TABLE_HEADERS_CONFIG,
  data() {
    return {
      payments: [],
      nextStatisticUpdate: '',
      statisticsYear: this.getCurrentYear(),
      influencersData: {}
    };
  },
  computed: {
    ...mapGetters({
      userId: rootTypes.getters.GET_USER_ID
    })
  },
  async mounted() {
    await this.loadFeeStatisticsList();
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING
    }),
    getFormattedDate(date) {
      const [year, month, day] = date.split('-');

      return `${day}-${this.getShortMonthName({ month, year })}-${year}`;
    },
    getFullMonthName({ month, year }) {
      const monthDate = new Date(year, month - 1, 1);
      return upperFirst(formatDate(monthDate, FULL_MONTH_NAME_PATTERN));
    },
    getShortMonthName({ month, year }) {
      const monthDate = new Date(year, month - 1, 1);
      return formatDate(monthDate, SMALL_MONTH_NAME_PATTERN);
    },
    getCurrentYear() {
      return new Date().getFullYear();
    },
    async loadFeeStatisticsList() {
      this.setLoading(true);

      const yearsToLoad = range(CONSULTATION_CALCULATION_START_YEAR, this.statisticsYear + 1);
      const statisticsToLoad = yearsToLoad.map(year =>
        getConsultationFeeStatistics(this.userId, year)
      );

      const statistics = await Promise.all(statisticsToLoad);

      this.payments = [];

      statistics.forEach(({ data: statistic }) => {
        const { monthlyStatistic = [], nextStatisticUpdate = '' } = statistic || {};

        this.payments = [...monthlyStatistic, ...this.payments];
        this.nextStatisticUpdate = nextStatisticUpdate;
      });

      this.setLoading(false);
    },
    showModal({ month, year, consultationAmountInNationalCurrency, consultationTime }) {
      this.$modal.show('send-consultation-fee-invoice-modal', {
        year,
        month: month - 1,
        fee: consultationAmountInNationalCurrency,
        consultationTime
      });
    },
    async downloadInvoice({ month, year }) {
      const { data } = await downloadConsultationFeeInvoice({
        doctorId: this.userId,
        month,
        year
      });

      if (!data) {
        return;
      }

      const blob = new Blob([data], { type: PDF_APPLICATION_TYPE });
      const fileNamePrefix = formatDate(
        new Date(year, month - 1),
        FULL_MONTH_NAME_AND_FULL_YEAR_PATTERN
      );
      const fileName = `${fileNamePrefix}.pdf`;

      downloadFile(blob, { fileName });
    },
    getShouldDisplaySendInvoiceButton({ month, hasInvoice, paid, year }) {
      const currentMonth = new Date().getMonth() + 1;

      return !paid && !hasInvoice && (month !== currentMonth || this.statisticsYear !== year);
    },
    async loadInfluencerData({ year, month, rowIndex }) {
      this.setLoading(true);

      try {
        const { data } = await getInfluencerStatistic({ userId: this.userId, year, month });

        this.influencersData = {
          ...this.influencersData,
          [rowIndex]: data
        };
      } catch (error) {
        this.influencersData = {
          ...this.influencersData,
          [rowIndex]: []
        };
      } finally {
        this.setLoading(false);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/helpers/colors';

.fee {
  &__container {
    .tabs {
      justify-content: flex-start;
      width: 100%;
    }
  }

  &__breadcrumbs {
    margin: 30px 0;
    font-size: 15px;
    font-weight: bold;
    color: $grey-color;
  }

  &__tabs {
    display: flex;
  }
}

.breadcrumbs {
  &__current {
    font-size: 18px;
    color: $main-title-color;
  }
}

.toggle-row-header {
  display: none;
}

.description {
  width: 100%;
  min-height: 200px;
  background-color: white;
  border-radius: 10px;
}

.mx-40 {
  margin: 0 40px;
}

.content {
  font-size: 13px;
  padding: 12px 0;

  &__text {
    margin: 12px 0;
  }
}

.table-wrapper {
  width: 100%;
  margin: 30px auto;
}

.cell-wrapper {
  height: auto;
  padding: 8px 0;
}

.cell-font {
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
}

.font-bold {
  font-weight: 600;
  line-height: 16px;
}

.invoice-action-link,
.invoice-action-link:link,
.invoice-action-link:hover {
  color: $blue-steel-color;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  text-decoration-line: underline;
}

@media (max-width: 767px) {
  .description {
    display: none;
  }

  .table-wrapper {
    width: auto;
    margin: auto;
  }

  .cell-wrapper {
    align-items: baseline;
    min-height: 35px;
    padding: 0 0 10px;
  }

  .month-cell-wrapper {
    flex-direction: row;
  }

  .cell-font {
    font-size: 12px;
    line-height: 14px;
  }

  .font-smaller {
    font-size: 10px;
    line-height: 12px;
  }

  .invoice-action-link,
  .invoice-action-link:link,
  .invoice-action-link:hover {
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
  }

  .fee {
    &__container {
      .tabs {
        &__container {
          margin: 14px 0 15px;

          .tab {
            flex-basis: 0;
            font-weight: 600;

            &:first-of-type {
              margin-right: 0;
            }
          }
        }
      }
    }

    &__breadcrumbs {
      margin: 15px 0 15px 15px;

      font-size: 12px;
    }
  }

  .breadcrumbs {
    &__current {
      font-size: 14px;
    }
  }

  .toggle-row-header {
    display: block;
  }
}
</style>
